export const names = `0\tJohn Turwire\tM
1\tRonald Shwaremranne\tM
2\tLaoislau Hamala\tM
3\tMegan Leicht\tF
4\tJack McCutchan\tM
5\tAaron Bactou\tM
6\tKate Tracyk\tF
7\tAnthony Gray\tM
8\tConnor Llewellyn\tM
9\tArcher McLeay\tM
10\tHannah McRean\tF
11\tPhil Michell\tM
12\tSarah Baillie\tF
13\tMael Pronovost\tM
14\tAsh Bullivant\tM
15\tJordan Muller\tM
16\tBen Bradford\tM
17\tRussel Henry\tM
18\tMichelle Fortier\tF
19\tErin Woodley\tF
20\tMaria Ster\tF
21\tSam Sten\tM
22\tTim Riddel\tM
47\tDave Wortley\tM
48\tStephan Pretrowski\tM
49\tCamille Goober\tF
50\tGisela Escher\tF
51\tMatt Houston\tM
52\tAdam Olejar\tM
53\tElska Olejar\tF
54\tJames Hamilton\tM
55\tPhillip Gibbons\tM
56\tClaire Robinson\tF
57\tNeil Seifart\tM
58\tJaime Brunet\tF
59\tTim Bailie\tM
142\tAnna Detmold\tF
143\tAlec Gray\tM
144\tKyla Edejar\tF
145\tDomminick Mallette\tM
146\tBethany Ladd\tF
147\tTom Kemenscy\tM
148\tDevin Gordon Coppieters\tM
149\tBriar Prestidge\tF
150\tMichelle Kovesi\tF`;
