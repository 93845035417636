export const beginner = {
  starts: `145\t1653744105
147\t1653744165
6\t1653744218
58\t1653744272
146\t1653744318
144\t1653744368
148\t1653744433
143\t1653744757
50\t1653744864
49\t1653744945
56\t1653744945
142\t1653744945
6\t1653745257
145\t1653745257
147\t1653745257
58\t1653745257
144\t1653745405`,

  ends: `145\t1653744249\t0
147\t1653744290\t0
6\t1653744350\t0
58\t1653744402\t0
146\t1653744438\t0
144\t1653744519\t0
143\t1653744925\t0
50\t1653745003\t0
56\t1653745066\t0
49\t1653745068\t0
142\t1653745070\t0
6\t1653745376\t0
58\t1653745384\t0
145\t1653745390\t0
144\t1653745550\t0`,
};

//undefined	1653746546
export const advanced = {
  starts: `48\t1653746491
11\t1653746601
2\t1653746630
1\t1653746658
52\t1653746689
49\t1653746722
16\t1653746752
53\t1653746784
8\t1653746819
13\t1653746866
14\t1653746903
4\t1653746939
18\t1653746970
17\t1653747007
50\t1653747050
57\t1653747111
146\t1653747148
0\t1653747183
147\t1653747222
10\t1653747258
19\t1653747291
6\t1653747330
145\t1653747424
5\t1653747479
20\t1653747515
55\t1653747580
51\t1653747629
7\t1653747680
9\t1653747736
15\t1653747770
48\t1653747813
5\t1653747841
1\t1653747879
58\t1653747916
22\t1653748219
54\t1653748280
11\t1653748334
21\t1653748378
49\t1653748417
2\t1653748490
12\t1653748546
4\t1653748593
52\t1653748635
8\t1653748680
0\t1653748754
53\t1653748816
17\t1653748875
18\t1653748930
14\t1653748979
10\t1653749026
20\t1653749111
47\t1653749180
19\t1653749239
51\t1653749301
5\t1653749357
57\t1653749440
55\t1653749500
54\t1653749580
9\t1653749642
21\t1653749696
7\t1653749776
50\t1653749855
59\t1653749943
22\t1653750006
6\t1653750069
16\t1653750139
147\t1653750207
48\t1653750293
1\t1653750346
52\t1653750399
5\t1653750450
11\t1653750530
4\t1653750581
2\t1653750630
145\t1653750681
53\t1653750749
49\t1653750827
17\t1653750881
51\t1653750940
18\t1653751014
8\t1653751062
20\t1653751128
9\t1653751203
19\t1653751266
10\t1653751413
146\t1653751450
12\t1653752118
55\t1653751587
5\t1653751656
47\t1653751745
9\t1653750787`,
  ends: `48\t1653746614\t0
5\t1653746678\t3
11\t1653746699\t0
2\t1653746757\t0
1\t1653746777\t0
52\t1653746806\t0
49\t1653746841\t3
16\t1653746883\t0
8\t1653746951\t1
53\t1653746973\t0
13\t1653746993\t0
14\t1653747028\t0
4\t1653747040\t0
18\t1653747093\t0
17\t1653747122\t0
57\t1653747256\t0
146\t1653747304\t1
50\t1653747305\t7
0\t1653747353\t0
147\t1653747366\t2
10\t1653747428\t0
6\t1653747462\t4
19\t1653747470\t0
145\t1653747570\t2
5\t1653747610\t1
20\t1653747671\t0
55\t1653747680\t0
51\t1653747747\t0
9\t1653747845\t0
48\t1653747932\t0
5\t1653747990\t2
1\t1653748004\t0
58\t1653748137\t2
22\t1653748357\t0
54\t1653748425\t0
11\t1653748433\t0
21\t1653748510\t1
49\t1653748537\t4
2\t1653748598\t2
12\t1653748660\t0
4\t1653748698\t0
52\t1653748754\t0
8\t1653748813\t0
0\t1653748900\t3
53\t1653748981\t0
17\t1653749018\t0
18\t1653749054\t0
14\t1653749094\t0
10\t1653749178\t3
20\t1653749278\t1
47\t1653749311\t0
19\t1653749401\t0
51\t1653749412\t0
5\t1653749518\t1
27\t1653749577\t0
55\t1653749601\t0
54\t1653749731\t0
9\t1653749754\t0
21\t1653749874\t0
7\t1653749950\t2
59\t1653750038\t0
50\t1653750059\t0
22\t1653750149\t0
6\t1653750237\t3
16\t1653750270\t0
147\t1653750390\t0
48\t1653750407\t0
1\t1653750472\t0
52\t1653750513\t0
5\t1653750596\t3
11\t1653750632\t0
4\t1653750692\t0
2\t1653750745\t0
145\t1653750868\t0
9\t1653750907\t2
53\t1653750944\t1
17\t1653751002\t0
51\t1653751060\t0
18\t1653751131\t0
8\t1653751194\t1
20\t1653751287\t0
9\t1653751317\t0
19\t1653751424\t0
10\t1653751547\t0
146\t1653751576\t4
12\t1653752231\t0
55\t1653751686\t0
5\t1653748224\t0
47\t1653751872\t0
0\t1653751972\t0
57\t1653749570\t0`,
};
